<template>
  <v-card>
    <v-tabs
      v-model="tab"
      centered
      icons-and-text
      background-color="secondary"
      dark
    >
      <v-tabs-slider color="warning"></v-tabs-slider>
      <v-tab to="#profesor">
        Profesor
        <v-icon>mdi-account-tie</v-icon>
      </v-tab>

      <v-tab to="#alumno">
        Alumno
        <v-icon>mdi-badge-account-horizontal</v-icon>
      </v-tab>
      <v-tab to="#control">
        Control
        <v-icon>mdi-account-supervisor</v-icon>
      </v-tab>
      <v-tab to="#monitor">
        Monitores
        <v-icon>mdi-monitor</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="profesor">
        <v-container>
          <v-row>
            <v-col >
            <v-list>
              <v-list-item v-for="(user, index) in usuario.profesor" :key="`user-${index}`">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-school</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.escuela.nombreCorto}}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="user.codigo">Codigo: {{ user.codigo }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.role == 'admin'"> Rol: Administrador</v-list-item-subtitle>
                    <v-chip style="max-inline-size: max-content;" v-if="user.activo" small outlined label color="green darken-1">Activo</v-chip>
                    <v-chip style="max-inline-size: max-content;" v-else small outlined label color="red darken-1">Inactivo</v-chip>
                   <v-list-item-title>{{ user.fechaRegistro }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="alumno">
        <v-container>
          <v-row>
            <v-col cols="12">
            <v-list>
              <v-list-item v-for="(user, index) in usuario.alumno" :key="`user-${index}`">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-school</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.escuela.nombreCorto}}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="user.codigo">Codigo: {{ user.codigo }}</v-list-item-subtitle>
                    <v-chip style="max-inline-size: max-content;" v-if="user.activo" small outlined label color="green darken-1">Activo</v-chip>
                    <v-chip style="max-inline-size: max-content;" v-else small outlined label color="red darken-1">Inactivo</v-chip>
                   <v-list-item-title>{{ user.fechaRegistro }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="control">
        <v-container>
          <v-row>
            <v-col cols="12">
            <v-list>
              <v-list-item v-for="(user, index) in usuario.padre" :key="`user-${index}`">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-school</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.escuela.nombreCorto}}
                  </v-list-item-title>
                  <v-list-item-subtitle>Codigo: {{ user.codigo }}</v-list-item-subtitle>
                    <v-chip style="max-inline-size: max-content;" v-if="user.activo" small outlined label color="green darken-1">Activo</v-chip>
                    <v-chip style="max-inline-size: max-content;" v-else small outlined label color="red darken-1">Inactivo</v-chip>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="monitor">
        <monitores/>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getDatosTabs } from "./usuarios.service";
import { DateTime } from "luxon";
import monitores from "./tabs/monitores.vue";

export default {
  name: "usuarioContenido",
  components: { monitores },
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  props:['profesor','alumno','padre'],
  data: () => ({
    loading: false,
    tab: null,
    idUsuario: null,
    usuario: {},
  }),
  watch: {
    sessionToken(value) {
      if (value) this.cargarDatosUsuario();
    },
    'usuario.padre':function(val){
      if(val.length)this.$emit('perteneceEscuelaPadre',true)
    },
    'usuario.alumno':function(val){
      if(val.length)this.$emit('perteneceEscuelaAlumno',true)
    },
    'usuario.profesor':function(val){
      if(val.length)this.$emit('perteneceEscuelaProfesor',true)
    }
  },
  created() {
    this.idUsuario = this.$route.params.idUsuario;
    if (this.sessionToken) this.cargarDatosUsuario();
  },
  methods: {
    async cargarDatosUsuario() {
      try {
        this.loading = true;
        const serverResponse = await getDatosTabs(this.idUsuario);
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        this.usuario = serverResponse.usuario;
        if(this.usuario.alumno.length){
          this.usuario.alumno.forEach(element => {
            var fecha = DateTime.fromISO(element.fechaRegistro);
            element.fechaRegistro = fecha.toLocaleString(DateTime.DATE_FULL);
          });
        }
      if(this.usuario.profesor.length){
          this.usuario.profesor.forEach(element => {
            var fecha = DateTime.fromISO(element.fechaRegistro);
            element.fechaRegistro = fecha.toLocaleString(DateTime.DATE_FULL);
          });
        }
        if(this.usuario.padre.length){
          this.usuario.padre.forEach(element => {
            var fecha = DateTime.fromISO(element.fechaRegistro);
            element.fechaRegistro = fecha.toLocaleString(DateTime.DATE_FULL);
          });
        }
        } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  }
};
</script>

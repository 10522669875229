import { getServerRequest, postServerRequest, deleteServerRequest } from '@/components/globals/services/serverRequest.service';
import { store } from '../../../store/store';

const httpURL = store.getters.httpURL;

export async function obtenerMonitores(registros, pagina, idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    let url = `${httpURL}/monitores/${idUsuario}/${registros}/${pagina}`;
    return await getServerRequest(url, config);
}

export async function agregarMonitor(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/monitores`;
    return await postServerRequest(url, data, config);
}

export async function eliminarMonitor(id) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/monitores/${id}`;
    return await deleteServerRequest(url, config);
}
<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-right">
          <v-btn
            small
            color="primary"
            class="btnPrimary"
            :class="{ 'disable-button': loading }"
            :disabled="loading"
            @click="agregarEscuela = true"
          >
            <v-icon small style="margin-right: 5px">
                mdi-sticker-plus-outline
            </v-icon>
            &nbsp; Agregar Escuela
          </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <datatable
          :datos="monitores"
          :pagination="pagination"
          :loading="loading"
          :page="pagina"
          :headers="headers"
          :totalItems="monitores.length"
          noDatosText="No hay datos disponibles"
          hideSearch
        >
          <template #item-menu="{ item }">
            <v-icon @click="eliminar(item._id)" small style="color: red">mdi-close</v-icon>
          </template>
        </datatable>
      </v-col>
    </v-row>
    <buscarPorCatalogoDialog
      v-if="agregarEscuela"
      :mostrar="agregarEscuela"
      :excluir="excluir"
      catalogo="escuela"
      titulo="Buscar Escuelas"
      @item-action="(id) => escuelaSeleccionada(id)"
      @cerrar="agregarEscuela = false"
    />
  </v-container>
</template>
<script>
  import { mapGetters } from "vuex";
  import datatable from "../../globals/template/widgets/datatable.vue";
  import buscarPorCatalogoDialog from "../../globals/template/widgets/catalogos/buscarPorCatalogo.vue";
  import { obtenerMonitores, agregarMonitor, eliminarMonitor } from "./monitores.service";

  export default {
    name: "MonitoresTab",
    computed: {
      ...mapGetters(["sessionToken"]),
    },
    components: {
      datatable,
      buscarPorCatalogoDialog
    },
    data: () => ({
      loading: false,
      idUsuario: '',
      monitores: [],
      excluir: [],
      pagina: 1,
      headers: [
        {
          text: "#",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "50px",
        },
        { text: "Escuela", value: "nombre", sortable: false, filterable: false },
        { text: "", value: "menu", sortable: false, filterable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 50,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
      agregarEscuela: false,
    }),
    watch: {
      sessionToken() {
        this.obtenerMonitoresEscuela();
      },
    },
    mounted() {
      this.idUsuario = this.$route.params.idUsuario;
      if (this.sessionToken) {
        this.obtenerMonitoresEscuela();
      }
    },
    methods: {
      async obtenerMonitoresEscuela() {
        try {
          this.loading = true;
          const sr = await obtenerMonitores(this.pagination.itemsPerPage, this.pagination.page, this.idUsuario);
          this.loading = false;
          if (!sr.ok) {
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.handleMonitores(sr);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      },
      handleMonitores({ monitores, total, pageStop, pageStart }) {
        this.monitores = monitores.map( (m, i) => ({ ...m, numero: i + 1 }) );
        this.excluir = monitores.map( m => m.idEscuela );
        this.pagination.itemsLength = total;
        this.pagination.pageStop = pageStop;
        this.pagination.pageStart = pageStart;
      },
      async escuelaSeleccionada(idEscuela) {
        try {
          this.loading = true;
          const sr = await agregarMonitor({ escuela: idEscuela, usuario: this.idUsuario });
          if (!sr.ok) {
            this.loading = false;
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.obtenerMonitoresEscuela();
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      },
      async eliminar(id) {
        try {
          this.loading = true;
          const sr = await eliminarMonitor(id);
          if (!sr.ok) {
            this.loading = false;
            this.$systemErrorMessage(sr.mensaje);
          } else {
            this.obtenerMonitoresEscuela();
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    }
  };
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","flat":"","dark":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"secondary"}}):_vm._e(),_c(VContainer,[_c(VTextField,{attrs:{"label":"Nombre","outlined":"","dense":"","disabled":_vm.loading,"hint":"Presione 'Enter' para buscar","error-messages":_vm.busquedaErrors},on:{"input":function($event){return _vm.$v.busqueda.$touch()},"blur":function($event){return _vm.$v.busqueda.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscarDatos()}},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}}),(_vm.datosFiltrados.length > 0)?_c(VList,{attrs:{"disabled":_vm.loading}},_vm._l((_vm.datosFiltrados),function(item,index){return _c(VListItem,{key:index,class:{'seleccionado': item.seleccionado},attrs:{"disabled":item.seleccionado},on:{"click":function($event){return _vm.itemSeleccionado(item._id)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.nombre)+" ")]),(item.codigo)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.codigo)+" ")]):_vm._e()],1)],1)}),1):_vm._e()],1),(_vm.resultados.length == 0 && !_vm.loading)?_c(VCardText,[_vm._v(" No se encontraron resultados. ")]):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","disabled":_vm.loading,"color":"primary"},on:{"click":function($event){_vm.resultados=[], _vm.busqueda = null, _vm.$emit('cerrar')}}},[_vm._v(" Cerrar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
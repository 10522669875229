<template>
  <v-app style="background-color: #b0bec5">
    <v-main>
      <v-dialog persistent v-model="loading" max-width="300">
        <v-card>
          <v-toolbar dense color="primary" dark
            >Cargando aplicación...
          </v-toolbar>
          <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { generarTokenCampus } from "./usuarios.service";
import { cerrarSesionService } from "../globals/template/mainView/main.service";
export default {
 props: {
    idUsuario: { type: String, default: () => null},
 },
  computed: {
    ...mapGetters(["appName", "redirect", "sessionToken", "apps"])
  },
  data() {
    return {
      loading: true,
      mensaje: "Cargando aplicación...",
    };
  },
  async created() {
    try {
      const app = this.apps.find(x=>x.tipo=='campus')
      const serverResponse = await generarTokenCampus(this.idUsuario, app.tipo, app._id);
      if (!serverResponse.ok) {
        this.$systemErrorMessage(serverResponse.mensaje);
          window.location.href = this.redirect;
      } else {
        const tokenCampus = serverResponse.tokenRedireccion;
        this.cambiarCampus(tokenCampus)
      }
    } catch (error) {
       this.$appErrorMessage();
      
    }
  },
  methods:{
    async cambiarCampus(tokenCampus) {
      try {
        const serverResponse = await cerrarSesionService();
        if (!serverResponse.ok) {
          this.blockMenu = false;
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$store.dispatch("setSessionToken", null);
          localStorage.removeItem("agnus-token");
          window.location.href = `${this.redirect}/cambioApp/${tokenCampus}`;
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
  }
};
</script>

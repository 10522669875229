<template>
<div>
  <v-card>
    <v-toolbar dense flat>
      <v-btn icon color="primary" @click="goBack()">
        <v-icon>mdi-arrow-left-bold</v-icon>
      </v-btn>
    </v-toolbar>
    <v-col class="justify-center text-center">
      <v-img
      
        v-if="usuario.avatar"
        contain
        max-height="300"
        :src="`${imageURL}/avatar/${usuario.avatar}`"
      ></v-img>
      <v-avatar v-else size="250">
        <v-icon size="230">mdi-account</v-icon>
      </v-avatar>
    </v-col>

    <v-sheet v-if="loading" class="pa-3">
      <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="list-item-avatar-two-line@5"
      ></v-skeleton-loader>
    </v-sheet>

    <v-list v-else dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Nombre</v-list-item-subtitle>
          <v-list-item-title>{{ usuario.nombreCompleto }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-at</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Correo</v-list-item-subtitle>
          <v-list-item-title>{{ usuario.correo }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="deep-purple darken-2">mdi-discord</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Discord</v-list-item-subtitle>
          <v-list-item-title>usuario#1234</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Registro</v-list-item-subtitle>
          <v-list-item-title>{{ usuario.fechaRegistro }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-email-check-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Activación</v-list-item-subtitle>
          <v-list-item-title>{{ usuario.fechaActivacion }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="!usuario.fechaActivacion">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                color="green darken-1"
                @click="activarCuenta(usuario._id)"
              >
                <v-icon>mdi-email-check</v-icon>
              </v-btn>
            </template>
            <span>Activar cuenta</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-login-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Última sesión</v-list-item-subtitle>
          <v-list-item-title>{{ usuario.fechaUltimaSesion }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-account-settings-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Tipo sesión</v-list-item-subtitle>
          <v-list-item-title>{{ usuario.tipoSesion }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-actions v-if="!usuario.master">
      <v-btn small  v-if="usuario.fechaActivacion || perteneceProfesor || perteneceAlumno || pertenecePadre" :loading="loading" @click="cambioSesionUsuario=true" style="width:-webkit-fill-available"> Ingresar con esta cuenta </v-btn>
      <v-btn small  v-else color="error" :loading="loading" @click="eliminarUsuario()" style="width:-webkit-fill-available"> Eliminar usuario </v-btn>
    </v-card-actions>

  </v-card>
  <cambioAppUser v-if="cambioSesionUsuario" :idUsuario="idUsuario"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import cambioAppUser from "./cambioAppUser.vue"
import { getDatosUsuarioService, activarCuentaUsuario, eliminarUsuarioService } from "./usuarios.service";

export default {
  name: "datosUsuario",
  computed: {
    ...mapGetters([ "sessionToken", "imageURL"]),
  },
  props:['perteneceAlumno','pertenecePadre','perteneceProfesor'],
  components:{cambioAppUser},
  data: () => ({
    loading: true,
    idUsuario: null,
    usuario: {},
    cambioSesionUsuario:false,
  }),
  watch: {
    sessionToken(value) {
      if (value) this.cargarDatosUsuario();
    },
  },
  created() {
    this.idUsuario = this.$route.params.idUsuario;
    if (this.sessionToken) this.cargarDatosUsuario();
  },
  methods: {
    async cargarDatosUsuario() {
      try {
        this.loading = true;
        const serverResponse = await getDatosUsuarioService(this.idUsuario);
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        this.setUserData(serverResponse.usuario);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    setUserData(usuario) {
      this.usuario = { ...usuario };
      var fecha = DateTime.fromISO(usuario.fechaRegistro);
      this.usuario.fechaRegistro = fecha.toLocaleString(DateTime.DATE_FULL);
      if (usuario.fechaActivacion) {
        var fecha = DateTime.fromISO(usuario.fechaActivacion);
        this.usuario.fechaActivacion = fecha.toLocaleString(DateTime.DATE_FULL);
      }
      if (usuario.fechaUltimaSesion) {
        var fecha = DateTime.fromISO(usuario.fechaUltimaSesion);
        this.usuario.fechaUltimaSesion = fecha.toLocaleString(DateTime.DATE_FULL) + ' - ' + fecha.toLocaleString(DateTime.TIME_SIMPLE) + ' hrs.';
      }
      if (usuario.tipoSesion)
        this.usuario.tipoSesion = usuario.tipoSesion.join(" - ");
    },
    async activarCuenta(idUsuario){
      try {
        this.loading = true;
        const serverResponse = await activarCuentaUsuario(idUsuario);
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        var fecha = DateTime.fromISO(serverResponse.fechaActivacion);
        this.usuario.fechaActivacion = fecha.toLocaleString(DateTime.DATE_FULL);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    goBack() {
      this.$router.push('/usuarios')
    },
    async eliminarUsuario(){
      try {
        this.loading = true;
        const serverResponse = await eliminarUsuarioService(this.idUsuario);
        this.loading = false;
        if (!serverResponse.ok) this.$systemErrorMessage(serverResponse.mensaje);
        else this.$router.push('/usuarios')
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<template>
  <v-dialog v-model="mostrar" persistent max-width="400">
    <v-card>
      <v-toolbar dense flat dark color="primary" class="text-h6">
        {{ titulo }}
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-container>
        <v-text-field
          label="Nombre"
          v-model="busqueda"
          outlined
          dense
          :disabled="loading"
          hint="Presione 'Enter' para buscar"
          :error-messages="busquedaErrors"
          @input="$v.busqueda.$touch()"
          @blur="$v.busqueda.$touch()"
          @keyup.enter="buscarDatos()"
        ></v-text-field>
        <v-list v-if="datosFiltrados.length > 0" :disabled="loading">
          <v-list-item
            v-for="(item, index) in datosFiltrados"
            :key="index"
            @click="itemSeleccionado(item._id)"
            :class="{'seleccionado': item.seleccionado}"
            :disabled="item.seleccionado"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.codigo">
                {{ item.codigo }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
      <v-card-text v-if="resultados.length == 0 && !loading">
        No se encontraron resultados.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          :disabled="loading"
          color="primary"
          @click="resultados=[], busqueda = null, $emit('cerrar')"
          >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { buscarCatalogosService } from "./catalogos.service";

export default {
  name: "buscarPorCatalogo",
  props: {
    mostrar: Boolean,
    titulo: { type: String, default: "Buscar Datos" },
    excluir: Array,
    cerrarAlSeleccionar: { type: Boolean, default: false },
    catalogo: { type: String, required: true },
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["imageURL"]),
    busquedaErrors() {
      const errors = [];
      if (this.busqueda !== null){
        if (!this.$v.busqueda.$dirty) return errors;
        !this.$v.busqueda.minLength && errors.push("Mínimo 2 caracteres.");
        !this.$v.busqueda.required && errors.push("Campo requerido.");
      }
      return errors;
    },
    datosFiltrados() {
      if(this.excluir){
        return this.resultados.map((x) => {
          const seleccionado = this.excluir.findIndex((y) => y === x._id) >= 0;
          return { ...x, seleccionado };
        });
      }
      return this.resultados;
    },
  },
  validations: {
    busqueda: { required, minLength: minLength(2) },
  },
  data() {
    return {
      loading: false,
      busqueda: null,
      resultados: [],
    };
  },
  methods: {
    async buscarDatos() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loading = true;
          const serverResponse = await buscarCatalogosService( this.busqueda, this.catalogo );
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            this.resultados = serverResponse.datos;
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    itemSeleccionado(idItem) {
      this.busqueda = null;
      this.$emit("item-action", idItem);
      if (this.cerrarAlSeleccionar) this.$emit("cerrar", idItem);
    },
  },
};
</script>
<style scoped>
.seleccionado {
  background-color: #f8f8f8;
  color: #a0a0a0 !important;
}
</style>

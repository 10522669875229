import { postServerRequest } from '@/components/globals/services/serverRequest.service';

import { store } from '../../../../../store/store';

const httpURL = store.getters.httpURL;

export const buscarCatalogosService = async(busqueda, catalogo, filtros = null) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/buscar/catalogos/${catalogo}`;
    return await postServerRequest(url, { busqueda, filtros }, config);
}
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"btnPrimary",class:{ 'disable-button': _vm.loading },attrs:{"small":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){_vm.agregarEscuela = true}}},[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v(" mdi-sticker-plus-outline ")]),_vm._v("   Agregar Escuela ")],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('datatable',{attrs:{"datos":_vm.monitores,"pagination":_vm.pagination,"loading":_vm.loading,"page":_vm.pagina,"headers":_vm.headers,"totalItems":_vm.monitores.length,"noDatosText":"No hay datos disponibles","hideSearch":""},scopedSlots:_vm._u([{key:"item-menu",fn:function({ item }){return [_c(VIcon,{staticStyle:{"color":"red"},attrs:{"small":""},on:{"click":function($event){return _vm.eliminar(item._id)}}},[_vm._v("mdi-close")])]}}])})],1)],1),(_vm.agregarEscuela)?_c('buscarPorCatalogoDialog',{attrs:{"mostrar":_vm.agregarEscuela,"excluir":_vm.excluir,"catalogo":"escuela","titulo":"Buscar Escuelas"},on:{"item-action":(id) => _vm.escuelaSeleccionada(id),"cerrar":function($event){_vm.agregarEscuela = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
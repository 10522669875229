<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <datosUsuarioComponent :perteneceProfesor="perteneceProfesor" :pertenecePadre="pertenecePadre" :perteneceAlumno="perteneceAlumno"/>
      </v-col>
      <v-col cols="12" md="8" sm="12">
        <usuarioContenidoComponent @perteneceEscuelaProfesor="profe" @perteneceEscuelaPadre="padre" @perteneceEscuelaAlumno="alumno" />
       
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import datosUsuarioComponent from "./datosUsuario.vue";
import usuarioContenidoComponent from "./usuarioContenido.vue";

export default {
  name: "usuarioView",
  components: { datosUsuarioComponent, usuarioContenidoComponent },
  data: () => ({
    loading: false,
    perteneceProfesor:false,
    pertenecePadre:false,
    perteneceAlumno:false
  }),
  methods:{
    profe(valor){
      if(valor) this.perteneceProfesor = valor
    },
    alumno(valor){
      if(valor) this.perteneceAlumno = valor
    },
    padre(valor){
      if(valor) this.pertenecePadre = valor
    }
  }
};
</script>
